<template>
  <div id="contractList">
    <!-- 搜索栏 -->
    <div class="select-box">
      <el-select class="input-with-select" v-model="tenant" filterable clearable placeholder="请选择客户">
        <el-option
          v-for="item in this.tenants"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>

      <el-input
        placeholder="请输入合同名称"
        v-model="searchName"
        class="input-with-select"
      >
      </el-input>
      <el-input
        placeholder="请输入签署方"
        v-model="participant"
        class="input-with-select"
      >
      </el-input>
      <el-select
        clearable
        placeholder="请选择合同状态"
        v-model="status"
        class="input-with-select"
      >
        <el-option
          v-for="elem of statusMap"
          :key="elem.value"
          :value="elem.value"
          :label="elem.label"
        ></el-option>
      </el-select>
      <!-- 发起时间 -->
      <el-date-picker
        class="input-with-select"
        v-model="sponsorTime"
        type="daterange"
        range-separator="至"
        start-placeholder="发起开始日期"
        end-placeholder="发起结束日期"
      >
      </el-date-picker>
      <!-- 创建时间 -->
      <el-date-picker
        class="input-with-select"
        v-model="createTime"
        type="daterange"
        range-separator="至"
        start-placeholder="创建开始日期"
        end-placeholder="创建结束日期"
      >
      </el-date-picker>
      <!-- 截止时间 -->
      <el-date-picker
        class="input-with-select"
        v-model="endTime"
        type="daterange"
        range-separator="至"
        start-placeholder="截止开始日期"
        end-placeholder="截止结束日期"
      >
      </el-date-picker>
      <div>
        <el-button type="primary" icon="el-icon-search" @click="search"
        >搜索</el-button
      >
      <el-button type="primary" @click="reset">重置</el-button>
      <el-button type="primary" @click="$refs.monthStatistics.open()"
        >本年度统计</el-button
      >
      <!-- <el-button type="primary" @click="$refs.useModal.open()"
        >发起签署</el-button
      > -->
      <el-button type="primary" @click="getDownloadUrl" :loading="hasTaskNo">
        {{ hasTaskNo ? '合同下载中' : '下载合同' }}
      </el-button>
      <el-button type="primary" @click="batchUrgeContract"
        >批量催办</el-button
      >
      </div>

    </div>
    <el-table
      ref="multipleTable"
      row-key="id"
      :data="tableData"
      style="width: 100%"
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        :selectable="checkSelectable"
        width="55"
      >
      </el-table-column>
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="taskId" label="合同编号" width="180">
      </el-table-column>
      <el-table-column prop="customName" label="客户名称" width="180">
      </el-table-column>
      <el-table-column prop="templateName" label="模板名称" width="180">
      </el-table-column>
      <el-table-column prop="name" label="合同名称" width="180">
      </el-table-column>
      <el-table-column prop="participant" label="签署方" width="180">
      </el-table-column>
      <el-table-column prop="failMsg" label="错误信息" width="180">
      </el-table-column>
      <el-table-column prop="sponsorTime" label="发起时间" width="150">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="150">
      </el-table-column>
      <el-table-column prop="endTime" label="截止时间" width="150">
      </el-table-column>
      <el-table-column prop="complateTime" label="签署时间" width="150">
      </el-table-column>
      <el-table-column prop="startTime" label="合同起始时间" width="150">
      </el-table-column>
      <el-table-column prop="expirationTime" label="合同到期时间" width="150">
      </el-table-column>
      <el-table-column prop="status" label="合同状态" width="180">
        <template slot-scope="scope">
          {{ scope.row.status | statusFilter }}
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="180">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="editContract(scope.row)"
            :disabled="editDisabled(scope.row)"
            >编辑</el-button
          >
          <el-button size="mini" type="text" @click="previewContract(scope.row)"
            >预览</el-button
          >
          <!-- <el-button size="mini" type="text" @click="writeContract(scope.row)" :disabled="scope.row.status!=='fill_progress'"
            >预填写</el-button
          > -->
          <el-dropdown size="mini">
            <span
              class="el-dropdown-link"
              style="font-size: 12px;color: #d55255;margin-left: 8px;"
            >
              更多<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="text"
                  :disabled="cancelDisabled(scope.row)"
                  @click="cancelContract(scope.row)"
                  >撤销</el-button
                ></el-dropdown-item
              >
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="text"
                  @click="extension(scope.row)"
                  :disabled="cancelDisabled(scope.row)"
                  >延期</el-button
                ></el-dropdown-item
              >
              <el-dropdown-item>
                <el-button
                  size="mini"
                  type="text"
                  :disabled="cancelDisabled(scope.row)"
                  @click="urgeContract(scope.row)"
                  >催办</el-button
                ></el-dropdown-item
              >
              <!-- <el-dropdown-item>
                <el-button
                  size="mini"
                  type="text"
                  @click="getDownloadUrl(scope.row)"
                  >下载</el-button
                ></el-dropdown-item
              > -->
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 16px;text-align: right;"
      background
      :page-sizes="[10, 20, 50, 100]"
      @size-change="handleSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :current-page="currentPage"
      :page-size="pageSize"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <useModal ref="useModal" @success="useSuccess" :isTemplate="false" />
    <createdIframe
      ref="createdIframe"
      :iframeUrl="iframeUrl"
      :iframeTitle="iframeTitle"
      @iframeClose="iframeClose"
    />
    <extensionModal ref="extensionModal"></extensionModal>
    <monthStatistics ref="monthStatistics"></monthStatistics>
  </div>
</template>

<script>
import useModal from './components/useModal'
import createdIframe from './components/createdIframe'
import extensionModal from './components/extensionModal'
import monthStatistics from './components/monthStatistics'
import dayjs from 'dayjs'
export default {
  components: {
    useModal,
    createdIframe,
    extensionModal,
    monthStatistics,
  },
  data() {
    return {
      tenants:'',
      tenant:'',
      searchName: '',
      // 签署方
      participant: '',
      // 发起时间
      sponsorTime: [],
      // 创建时间
      createTime: [],
      // 截止时间
      endTime: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      loading: false,
      iframeUrl: '',
      iframeTitle: '',
      tableSelect: [],
      hasTaskNo: false,
      taskNo: '',
      status: '',
      ids:[],
      tableData:[],
      statusMap: [
        { label: '创建中', value: 'task_created' },
        { label: '已创建', value: 'finish_creation' },
        { label: '填写中', value: 'fill_progress' },
        { label: '填写已完成', value: 'fill_completed' },
        { label: '签署中', value: 'sign_progress' },
        { label: '签署已完成', value: 'sign_completed' },
        { label: '任务已完成', value: 'task_finished' },
        { label: '任务异常停止', value: 'task_terminated' },
        { label: '已逾期', value: 'expired' },
        { label: '作废中', value: 'abolishing' },
        { label: '已作废', value: 'revoked' },
      ],
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        task_created: '创建中',
        finish_creation: '已创建',
        fill_progress: '填写中',
        fill_completed: '填写已完成',
        sign_progress: '签署中 ',
        sign_completed: '签署已完成',
        task_finished: '任务已完成',
        task_terminated: '任务异常停止',
        expired: '已逾期',
        abolishing: '作废中',
        revoked: '已作废',
      }
      return statusMap[status]
    },
  },
  methods: {
    //查询客户信息
    async listTenantInfos() {
      this.tableLoading = true;
      await this.api.salaryApi.finTenants().then(res => {
        console.log("-----",res)
          this.tenants = res.data;
        });
    },

    reset() {
      this.tenant = ''
      this.searchName = ''
      this.status = ''
      this.participant= ''
      // 发起时间
      this.sponsorTime= []
      // 创建时间
      this.createTime=[]
      // 截止时间
      this.endTime= []
      this.search()
      this.listTenantInfos();
    },
    checkSelectable(row) {
      return row.status === 'sign_completed' || row.status === 'task_finished' || row.status === 'sign_progress' || row.status === 'fill_progress'
    },
    iframeClose() {
      this.loadTableData()
    },
    editDisabled(row) {
      const status = ['task_created', 'finish_creation']
      return !status.includes(row.status)
    },
    cancelDisabled(row) {
      const status = ['fill_progress', 'fill_completed', 'sign_progress']
      return !status.includes(row.status)
    },
    getDownloadUrl(row) {
      if (!this.tableSelect.length) {
        return this.$message.error('请选择合同')
      }
      this.api.contractApi
        .getDownloadUrl(this.tableSelect.map((elem) => elem.id))
        .then((res) => {

          if (res.data.code === 200) {
            this.hasTaskNo = true
            this.taskNo = res.data.data
            if (!this.taskNo.includes('https://')) {
              this.setTimeLoop()
            } else {
              window.open(res.data.data, '_blank')
            }
            this.$refs.multipleTable.clearSelection()
          } else {
            this.$message.error(res.data.data.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.hasTaskNo = false
        })
    },
    setTimeLoop() {
      setTimeout(() => {
        this.api.contractApi.getDownloadResultUrl(this.taskNo).then((res) => {
          if (res.data.code === 200) {
            if (!res.data.data) {
              this.setTimeLoop()
            } else {
              this.hasTaskNo = false
              this.taskNo = ''
              this.downloadFile(res.data.data)
            }
          } else {
            this.$message.error(res.data.data.msg)
          }
        })
      }, 2000)
    },
    downloadFile(url) {
      const aDom = document.createElement('a')
      aDom.setAttribute('href', url)
      aDom.click()
    },
    handleSelectionChange(value) {
      this.tableSelect = value
    },
    // 编辑合同
    async editContract(row) {
      try {
        const res = await this.api.contractApi.getContractEditUrl({
          id: row.id,
          redirectUrl:
            window.location.origin +
            window.location.pathname +
            'contract-success.html',
        })
        if (res.data.code === 200) {
          this.iframeUrl = res.data.data
          this.iframeTitle = '编辑合同'
          this.$nextTick(() => {
            this.$refs.createdIframe.open()
          })
        } else {
          this.$message.error(res.data.data.msg)
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 预览合同
    async previewContract(row) {
      // 预览模板的逻辑
      try {
        const res = await this.api.contractApi.getContractPreviewUrl({
          id: row.id,
        })
        if (res.data.code === 200) {
          this.iframeUrl = res.data.data
          this.iframeTitle = '预览合同'
          this.$nextTick(() => {
            this.$refs.createdIframe.open()
          })
        } else {
          this.$message.error(res.data.data.msg)
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 预填写
    async writeContract(row) {
      // 预览模板的逻辑
      try {
        const res = await this.api.contractApi.getPreviewWrite({
          id: row.id,
        })
        if (res.data.code === 200) {
          this.iframeUrl = res.data.data
          this.iframeTitle = '合同预填写'
          this.$nextTick(() => {
            this.$refs.createdIframe.open()
          })
        } else {
          this.$message.error(res.data.data.msg)
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 撤销合同
    async cancelContract(row) {
      try {
        const confirm = await this.$confirm(
          '此操作将撤销合同, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        if (confirm !== 'confirm') {
          return
        }
        const res = await this.api.contractApi.cancelContract({ id: row.id })
        if (res.data.code === 200) {
          this.$message.success('撤销合同成功')
          this.loadTableData()
        } else {
          this.$message.error(res.data.data.msg)
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 延期合同
    extension(row) {
      this.$refs.extensionModal.open(row)
    },
    // 催办合同
    async urgeContract(row) {
      try {
        const confirm = await this.$confirm(
          '此操作将发送短信/邮箱催办签署人, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        if (confirm !== 'confirm') {
          return
        }
        const res = await this.api.contractApi.urgeContract({ id: row.id })
        if (res.data.code === 200) {
          this.$message.success('催办成功')
          this.loadTableData()
        } else {
          this.$message.error(res.data.data.msg)
        }
      } catch (error) {
        console.log(error)
      }
    },
    extractIds() {
      this.ids = this.tableSelect.map(elem => elem.id.toString())
    },
    // 批量催办合同
    async batchUrgeContract(row) {
      if (!this.tableSelect.length) {
        return this.$message.error('请选择合同')
      }
      try {
        const confirm = await this.$confirm(
          '此操作将发送短信/邮箱催办签署人, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        if (confirm !== 'confirm') {
          return
        }
        const dataToSend = {
          ids: this.ids = this.tableSelect.map(elem => elem.id.toString())
        };
        const res = await this.api.contractApi.batchUrgeContract(dataToSend)
        if (res.data.code === 200) {
          this.$message.success('催办成功')
          this.loadTableData()
        } else {
          this.$message.error(res.data.msg)
        }
      } catch (error) {
        console.log(error)
      }
    },
    useSuccess(iframeUrl) {
      this.iframeUrl = iframeUrl
      this.iframeTitle = '发起签署'
      this.$nextTick(() => {
        this.$refs.createdIframe.open()
      })
    },
    search() {
      // 实现搜索逻辑
      this.currentPage = 1
      this.loadTableData()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.currentPage = 1
      this.loadTableData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.loadTableData()
    },
    loadTableData() {
      // 实现数据加载逻辑
      const data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        tenantId: localStorage.getItem('platform'),
      }
      if (this.searchName) {
        data.name = this.searchName
      }
      if (this.participant) {
        data.participant = this.participant
      }
      if (this.status) {
        data.status = this.status
      }
      if (this.tenant) {
        data.tenant = this.tenant
      }

      if(this.sponsorTime&&this.sponsorTime.length){
        data.sponsorTimeStart = dayjs(this.sponsorTime[0]).format('YYYY-MM-DD 00:00:00')
        data.sponsorTimeEnd = dayjs(this.sponsorTime[1]).format('YYYY-MM-DD 23:59:59')
      }
      if(this.createTime&&this.createTime.length){
        data.createTimeStart = dayjs(this.createTime[0]).format('YYYY-MM-DD 00:00:00')
        data.createTimeEnd = dayjs(this.createTime[1]).format('YYYY-MM-DD 23:59:59')
      }
      if(this.endTime&&this.endTime.length){
        data.endTimeStart = dayjs(this.endTime[0]).format('YYYY-MM-DD 00:00:00')
        data.endTimeEnd = dayjs(this.endTime[1]).format('YYYY-MM-DD 23:59:59')
      }
      this.loading = true
      this.api.contractApi
        .listPageContract(data)
        .then((res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data.records
            this.total = res.data.data.total
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
        this.listTenantInfos();
    },
  },
  created() {
    this.loadTableData()
  },
}
</script>
<style scoped>
.select-box {
  display: flex;
  margin-bottom: 16px;
  flex-wrap: wrap;
}
.input-with-select {
  width: 300px;
  margin-right: 16px;
  margin-bottom: 16px;

}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.template-list {
  padding: 20px;
  background-color: #fff;
}
</style>

