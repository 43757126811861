<template>
  <div class="template-list">
    <!-- 搜索栏 -->
    <div class="select-box">
      <div style="text-align: left;">
        <el-select style="margin-right: 16px;" v-model="tenant" filterable clearable placeholder="请选择客户">
            <el-option
              v-for="item in this.tenants"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>

        <el-input
          placeholder="请输入模板名称"
          v-model="searchName"
          class="input-with-select"
        >
        </el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
        <el-button type="primary" @click="reset">重置</el-button>
        <el-button v-if="isPlatform" type="primary" @click="$refs.createdTemplate.open()"
          >新增模板</el-button
        >
      </div>
    </div>
    <!-- 表格 -->
    <el-table v-loading="loading" :data="tableData" style="width: 100%">
      <el-table-column prop="templateId" label="模板ID"> </el-table-column>
      <el-table-column prop="name" label="模板名称"> </el-table-column>
      <el-table-column prop="tenantName" label="客户名称"> </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.status === 'valid'"
            >启用</el-tag
          >
          <el-tag type="danger" v-else-if="scope.row.status === 'invalid'"
            >禁用</el-tag
          >
          <el-tag type="info" v-else>草稿</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="180">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            :disabled="scope.row.status !== 'valid'"
            size="mini"
            type="text"
            @click="handleUse(scope.row)"
            >使用</el-button
          >
          <el-button
            :disabled="scope.row.status === 'valid' && isPlatform"
            size="mini"
            type="text"
            @click="handleEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="text"
            @click="handleCopy(scope.row)"
            >添加</el-button
          >
          <el-button
            :disabled="scope.row.status !== 'invalid'"
            size="mini"
            type="text"
            @click="handleEnable(scope.row)"
            >启用</el-button
          >
          <el-button
            :disabled="scope.row.status !== 'valid'"
            size="mini"
            type="text"
            @click="handleDisable(scope.row)"
            >禁用</el-button
          >
          <el-button size="mini" type="text" @click="handlePreview(scope.row)"
            >预览</el-button
          >
          <el-button size="mini" type="text" @click="handleDetele(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      style="margin-top: 16px;text-align: right;"
      background
      :page-sizes="[20, 50, 100]"
      @size-change="handleSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems"
      :current-page="currentPage"
      :page-size="pageSize"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <createdTemplate ref="createdTemplate" @success="templateSuccess" />
    <createdIframe
      ref="createdIframe"
      :iframeUrl="iframeUrl"
      :iframeTitle="iframeTitle"
      @iframeClose="iframeClose"
    />
    <useModal ref="useModal" @success="useSuccess" />
    <copyModal ref="copyModal" @success="getList" />
  </div>
</template>

<script>
import createdTemplate from './components/createdTemplate'
import copyModal from './components/copyTemplate'
import createdIframe from './components/createdIframe'
import useModal from './components/useModal'
export default {
  name: 'TemplateList',
  components: {
    createdTemplate,
    createdIframe,
    useModal,
    copyModal
  },
  data() {
    return {
      loading: false,
      searchName: '',
      tableData: [],
      totalItems: 0,
      currentPage: 1,
      pageSize: 10,
      iframeUrl: '',
      iframeTitle: '',
      isPlatform:false,
      tenant:'',

    }
  },
  created() {
    this.getList()
    this.isPlatformFn()
    this.listTenantInfos();
  },
  methods: {
    //查询客户信息
    async listTenantInfos() {
      this.tableLoading = true;
      await this.api.salaryApi.finTenants().then(res => {
        console.log("-----",res)
          this.tenants = res.data;
        });
    },

    handleCopy(row){
      this.$refs.copyModal.open(row.id)
    },
    reset(){
      this.tenant = ''
      this.searchName = ''
      this.handleSearch()
    },
    async handleDetele(row){
      try {
        await this.$confirm('此操作将永久删除该模板, 是否继续?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消',type: 'warning'})
        const res = await this.api.contractApi.deteleTemplate({id:row.id})
        if(res.data.code===200){
          this.$message.success('删除成功')
          this.getList()
        }else{
          this.$message.error(res.data,msg)
        }
      } catch (error) {
        console.log(error)
      }
    },
    isPlatformFn(){
      this.api.contractApi.isPlatform({
        tenantId:localStorage.getItem('platform')
      }).then(res=>{
        if(res.data.code===200){
          this.isPlatform = res.data.data
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    iframeClose(){
      this.getList()
    },
    templateSuccess(iframeUrl) {
      this.iframeUrl = iframeUrl
      this.iframeTitle = '创建模板'
      this.$nextTick(() => {
        this.$refs.createdIframe.open()
      })
    },
    useSuccess(iframeUrl) {
      this.iframeUrl = iframeUrl
      this.iframeTitle = '使用模板'
      this.$nextTick(() => {
        this.$refs.createdIframe.open()
      })
    },
    async handleEdit(row) {
      try {
        const res = await this.api.contractApi.getEditUrl({
          id: row.id,
          redirectUrl: window.location.origin + window.location.pathname + 'contract-success.html',
        })
        if (res.data.code === 200) {
          this.iframeUrl = res.data.data
          this.iframeTitle = '编辑模板'
          this.$nextTick(() => {
            this.$refs.createdIframe.open()
          })
        } else {
          this.$message.error(res.data.data.msg)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getList() {
      const data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        tenantId: localStorage.getItem('platform'),
      }
      if (this.searchName) {
        data.name = this.searchName
      }
      if (this.tenant) {
        data.tenant = this.tenant
      }

      this.loading = true
      this.api.contractApi
        .listPageTemplate(data)
        .then((res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data.records
            this.totalItems = res.data.data.total
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch() {
      this.currentPage = 1
      this.getList()
      // 实现搜索逻辑
    },
    handleUse(row) {
      // 使用模板的逻辑
      this.$refs.useModal.open(row.id)
    },
    async handleEnable(row) {
      // 启用模板的逻辑
      try {
        const res = await this.api.contractApi.enableTemplate({ id: row.id })
        if (res.data.code === 200) {
          row.status = 'valid'
          this.$message.success('启用成功')
        } else {
          this.$message.error(res.data.data.msg)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleDisable(row) {
      // 禁用模板的逻辑
      try {
        const res = await this.api.contractApi.disableTemplate({ id: row.id })
        if (res.data.code === 200) {
          row.status = 'invalid'
          this.$message.success('禁用成功')
        } else {
          this.$message.error(res.data.data.msg)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handlePreview(row) {
      // 预览模板的逻辑
      try {
        const res = await this.api.contractApi.getPreviewUrl({
          id: row.id,
        })
        if (res.data.code === 200) {
          this.iframeUrl = res.data.data
          this.iframeTitle = '预览模板'
          this.$nextTick(() => {
            this.$refs.createdIframe.open()
          })
        } else {
          this.$message.error(res.data.data.msg)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val
      // 加载新页面的数据
      this.getList()
    },
    handleSizeChange(value) {
      this.pageSize = value
      this.currentPage = 1
      this.getList()
    },
  },
}
</script>

<style scoped>
.select-box {
  display: flex;
}
.input-with-select {
  width: 300px;
  margin-right: 16px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.template-list {
  padding: 20px;
  background-color: #fff;
}
</style>

