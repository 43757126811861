<template>
  <div class="home" id="quotaReviewList">
    <div style="text-align: left" >
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        
        <el-form-item label="交易时间:">
        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="queryForm.time"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="起"
                    end-placeholder="止"
                    :default-time="['00:00:00','23:59:59']">
                </el-date-picker>
          </el-form-item>
          <el-form-item class="search">
            <el-button type="primary" @click="loadTable" icon="el-icon-search">查询</el-button>
            <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
          </el-form-item>
        </el-form>
    </div>
   
    <div>
      <el-table
        :data="tableData"
        :header-cell-style="tableHeader"
        border>
        <el-table-column prop="sss" label="序号" width="50" align="center">
          <template slot-scope="scope">
            <span>
              {{
              scope.$index + 1 + (queryForm.pageNo - 1) * queryForm.pageSize
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="交易时间" :show-overflow-tooltip="true" align="center" width="200px"/>
        <el-table-column prop="id" label="流水号" :show-overflow-tooltip="true" align="center" width="200px"/>
        <el-table-column prop="walletId" label="子账户" :show-overflow-tooltip="true" align="center" width="200px"/>
        <el-table-column label="交易金额(元)" align="center"><template slot-scope="scope" >{{accDiv(scope.row.amount,100)}}</template></el-table-column> 
        <el-table-column prop="type" label="交易类型" align="center"  :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.type == '1'">入金</span>
            <span v-if="scope.row.type == '2'">转账</span>
            <span v-if="scope.row.type == '3'">出金</span>
            <span v-if="scope.row.type == '4'">非同名出金</span>
            <span v-if="scope.row.type == '5'">线下入金</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="资金用途" align="center" :show-overflow-tooltip="true" width="200px"/>
        <el-table-column prop="tradingPartyAccNo" label="交易方账号" :show-overflow-tooltip="true" width="200px" align="center" />
        <el-table-column prop="tradingPartyName" label="交易方名称" :show-overflow-tooltip="true" width="200px" align="center" />
        <el-table-column prop="amountType" label="收支类型" align="center"  :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.amountType == '1'">收入</span>
            <span v-if="scope.row.amountType == '2'">支出</span>
          </template>
        </el-table-column>
         <el-table-column prop="state" label="交易状态" align="center" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.state == '1'">处理中</span>
            <span v-if="scope.row.state == '2'">成功</span>
            <span v-if="scope.row.state == '3'">失败</span>
            <span v-if="scope.row.state == '4'">部分成功</span>
          </template>
        </el-table-column>
        
        
      </el-table>
      <!-- 分页栏 -->
      <el-pagination
        id="page"
        background
        align="right"
        @size-change="handleSizeChange"
        @current-change="salarysizeChange"
        :page-sizes="[20, 30, 50]"
        :page-size="queryForm.pageSize"
        :current-page="queryForm.pageNo"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryForm:{
        name:'',
        idno:'',
        mobile:'',
        time:[],
        minCreateTime:'',
        maxCreateTime:'',
        pageNo:1,
        pageSize:10
      },
      tenants:'',
      tableData:[],
      total:0,
      itemList:[],
      itemLists:[],
      diction: {
          code: "summaryStatus",
      },
      
      PayrollInfo:'',
      loading: false,
      salaryInfo:{
        buscod:'',
        yurref:'',
        bgndat:'',
        enddat:'',
        salarySummarizingId:'',
        salarySummarizingRecordsId:'',
        zhtenantID:'',
        createTime:'',
      },
      
    };
  },
  async created() {
    this.loadTable();
    
  },
  // 挂载查询方法
  mounted() {
  },
  methods: {
    // 查询
    async loadTable() {
      this.tableLoading = true;
      // var time0 = this.queryForm.time[0]
      // var time1 = this.queryForm.time[1]
      // time0 = time0.split(' ')[0]
      // var reg0 = new RegExp("-","g"); // 加'g'，删除字符串里所有的"a"
      // time0 = time0.replace(reg0,"");

      // time1 = time1.split(' ')[0]
      // var reg1 = new RegExp("-","g"); // 加'g'，删除字符串里所有的"a"
      // time1 = time1.replace(reg1,"");

      // this.queryForm.begdat = time0
      // this.queryForm.enddat = time1

      var time0 = this.queryForm.time ? this.queryForm.time[0] : ''
      var time1 = this.queryForm.time ? this.queryForm.time[1] : ''
      this.queryForm.minCreateTime = time0
      this.queryForm.maxCreateTime = time1

      

      await this.api.salaryApi.subunitHistoricalTransactions(this.queryForm).then(res => {
          res = res.data;
          console.log("---------交易数据",res);
          if(res.code == 200){
            this.tableData = res.data.data.records;
            this.total = res.data.data.total
          }else{
            this.tableData =  null;
            this.$message.error(res.msg);
          }
        });
        // this.listPayrollInfos();
        // this.listTenantInfos()
    },

    
    
 // async findDictionariesItemList(param) {
    //   let response = await this.api.platformApi.findDictionariesItemList(param);
    //   console.log("数据字典", response);
    //   this.itemList = response.data.data;
    // },
    // 分页改变时触发
    salarysizeChange(page) {
      this.queryForm.pageNo = page;
      this.loadTable();
    },
    handleSizeChange(size) {
      this.queryForm.pageSize = size;
      this.loadTable();
    },
    // 重置
    onReset() {
      this.queryForm.time=[],
      this.queryForm.name='';
      this.queryForm.idno='';
      this.queryForm.mobile='';
      this.loadTable();
    },
    // 查看函数
    noDetails(row){
        this.$router.push({path: '/payrollLogDetails',query: {salarySummarizingRecordsId: row.id,createTime:row.createTime}})
    },
    // 导出
    derive() {},
    // async findDictionariesItemLists(param) {
    //   let response = await this.api.platformApi.findDictionariesItemList(param);
    //   console.log("数据字典", response);
    //   this.itemLists = response.data.data;
    // },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    afi(fmt){
      // Date.prototype.format = function(fmt) { 
          var o = { 
              "M+" : this.getMonth()+1,                 //月份 
              "d+" : this.getDate(),                    //日 
              "h+" : this.getHours(),                   //小时 
              "m+" : this.getMinutes(),                 //分 
              "s+" : this.getSeconds(),                 //秒 
              "q+" : Math.floor((this.getMonth()+3)/3), //季度 
              "S"  : this.getMilliseconds()             //毫秒 
          }; 
          if(/(y+)/.test(fmt)) {
                  fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
          }
          for(var k in o) {
              if(new RegExp("("+ k +")").test(fmt)){
                  fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
              }
          }
          return fmt; 
      // }       
    },
    //除法函数
    accDiv(arg1, arg2){
         if(arg1!=null){
          var t1 = 0, t2 = 0, r1, r2;
          try {
            t1 = arg1.toString().split(".")[1].length;
          }
          catch (e) {
          }
          try {
            t2 = arg2.toString().split(".")[1].length;
          }
          catch (e) {
          }
          r1 = Number(arg1.toString().replace(".", ""));
          r2 = Number(arg2.toString().replace(".", ""));
          return (r1 / r2) * Math.pow(10, t2 - t1);
         }
         else{
          return null
         }
        }
  }
};
</script>

<style scoped>
#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}
.sys-info{
  display: flex;
  font-size: 20px;
  margin-bottom: 10px;
  background: #00FFFF;
  border-radius: 10px;
}
.info-a{
  margin: 0 100px 0 10px;
}
.info-b{

}
.info-jc{
  color: red;
  font-weight: 900;
  font-size: 24px;
}
.search{
  margin-left: 50px;
}
</style>
