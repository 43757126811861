<template>
  <div id="editUser">
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="16" :md="12">
        <el-form
          id="from"
          :model="ruleForm"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <input type="hidden" v-model="ruleForm.id" />
          <el-form-item label="*账户" prop="account">
            <el-input
              v-model="ruleForm.account"
              onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
            ></el-input>
          </el-form-item>
          <el-form-item label="*用户名" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="gender">
            <el-select v-model="ruleForm.gender" placeholder="请选择性别">
              <el-option
                v-for="item in optionsgender"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系方式" prop="mobile">
            <el-input v-model="ruleForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="所属部门" prop="dep">
            <!-- <el-select
            v-model="depatment"
            placeholder="请选择"
            @change="changerole()">
            <el-option
              v-for="item in depss"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select> -->
            <el-cascader
              v-model="depatments"
              :options="groupTree"
              :props="{ multiple: true, checkStrictly: true }"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="所属角色" prop="role">
            <!-- <el-select
            v-model="depatment1"
            placeholder="请选择">
            <el-option
              v-for="item in depss1"
              :key="item.role"
              :label="item.name"
              :value="item.role"
            ></el-option>
          </el-select> -->
            <el-cascader
              v-model="selectedRoles"
              :key="refresh"
              :options="roleTree"
              :props="{ multiple: true, checkStrictly: true }"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row id="anniu22" type="flex">
      <el-col>
        <el-button type="success" @click="canceledit()">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >修改</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>
<script>
//启用
import { editUser } from "@/service/moudules/platformApi";
//watch
import { findUserGroupRoleById } from "@/service/moudules/platformApi";
//查询所有部门树
import { groupTree } from "@/service/moudules/platformApi";
//根据所选部门找对应的角色
import { groupRoleTree } from "@/service/moudules/platformApi";
export default {
  created() {
    console.log(this.user);
    this.getGroupTree();
  },
  props: ["user"],
  watch: {
    user: {
      async handler(val) {
        this.ruleForm = val;
        console.log(val.id);
        let response = await this.api.platformApi.findUserGroupRoleById(val);
        // this.$axios
        //   .post(
        //     "/sysUser/findUserGroupRoleById",
        //     JSON.stringify(val),
        //     this.$jsonHeaders
        //   )
        //   .then((response) => {
        // console.log(response)
        // console.log('=================================')
        // console.log('department', response.data.data.depatments)
        // console.log('selectedRoles', response.data.data.selectedRoles)
        // console.log('=================================')
        ++this.refresh;
        this.depatments = response.data.data.depatments;
        this.selectedRoles = response.data.data.selectedRoles;
      },
      immediate: true,
    },
    depatments(val) {
      this.changeRoleTree();
    },
  },
  mounted: function () {
    this.loadCgy();
    // this.getDep();
  },
  data() {
    return {
      optionsgender: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "0",
          label: "女",
        },
      ],
      ruleForm: {
        id: "",
        account: "",
        name: "",
        gender: "",
        mobile: "",
        gname: "",
        rname: "",
      },
      id: "",
      depatment: "",
      depatment1: "",
      depss: [],
      depss1: [],
      orole: "",
      oguid: "",
      depatments: [],
      roleTree: [],
      groupTree: [],
      selectedRoles: [],
      refresh: 0,
    };
  },
  methods: {
    async edit() {
      if (this.depatments.length === 0 || this.selectedRoles.length === 0) {
        this.$message.error("请选择部门和角色");
        return false;
      } else {
        var params = {
          groups: this.depatments,
          roles: this.selectedRoles,
          user: this.ruleForm,
          sessionId: this.$getCookie("sessionId"),
        };
        let response = await this.api.platformApi.editUser(params);
        if (response.data == false) {
          this.$message({
            message: "此用户在该组已有该角色请重新选择",
            type: "warning",
          });
        } else {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.$emit("closeDialog");
          this.location.reload();
        }
      }
    },

    loadCgy: function () {
      this.ruleForm = this.user;
      this.orole = this.user.rid;
      this.oguid = this.user.guid;
      // this.ruleForm.id = temp.id;
      // this.ruleForm.account = temp.account;
      // this.ruleForm.name = temp.name;
      // this.ruleForm.gender = temp.gender;
      // this.ruleForm.mobile = temp.mobile;
      // this.ruleForm.gname = temp.gname;
      // this.ruleForm.rname = temp.rname;
      // this.orole=temp.rid;
      // this.oguid=temp.guid;
    },

    submitForm(formName) {
      this.edit();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // getDep() {
    //   axios
    //     .get("/sysUser/findgroup", {})
    //     .then((response) => {
    //       this.depss = response.data.data;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    // changerole() {
    //   let data = {};
    //   var params = {
    //     group: this.depatment,
    //   };
    //   axios
    //     .get("/sysUser/findByGroupRole", params)
    //     .then((response) => {
    //       this.depss1 = response.data.data;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    canceledit() {
      this.$emit("closeDialog");
    },
    async findGroupTree() {
      var params = {};

      let response = await this.api.platformApi.groupTree(params);
      if (response.data.code == 200) {
        this.groupTree = response.data.data;
      } else {
        this.$message.error(response.data.msg);
      }
    },
    async findGroupRoleTree() {
      var params = {
        checkedGroups: this.depatments,
      };
      let response = await this.api.platformApi.groupRoleTree(params);
      // console.log(response)
      if (response.data.code == 200) {
        // console.log(response.data.data)
        this.roleTree = response.data.data;
      } else {
        this.$message.error(response.data.msg);
      }
    },
    //查询全部的部门树
    getGroupTree() {
      this.findGroupTree();
    },
    changeRoleTree() {
      this.findGroupRoleTree();
    },
  },
};
</script>
<style scoped>
#editUser .el-cascader {
  width: 100%;
}

#anniu22 {
  text-align: right;
  border-top: 1px solid rgba(228, 228, 228, 1);
  padding-top: 10px;
  padding-right: 30px;
  border-radius: 5px;
}

#editUser .el-select {
  width: 100%;
}

#anniu22 button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#editUser .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

#editUser .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

#editUser .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

#editUser .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
