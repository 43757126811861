<template>
  <div class="template-list">
    <!-- 搜索栏 -->
    <div class="select-box">
      <el-select style="margin-right: 16px;" v-model="tenant" filterable clearable placeholder="请选择客户">
            <el-option
              v-for="item in this.tenants"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        <el-button type="primary" @click="reset">重置</el-button>
        <el-button type="primary" @click="handleUse">新增任务</el-button>

    </div>
    <!-- 表格 -->
    <el-table v-loading="loading" :data="tableData" style="width: 100%">
      <el-table-column prop="templateId" label="模板ID"> </el-table-column>
      <el-table-column prop="templateName" label="模板名称" width="180"></el-table-column>
      <el-table-column prop="name" label="批量任务主题"> </el-table-column>
      <el-table-column prop="createdCount" label="已创建任务数" width="180"></el-table-column>
      <el-table-column prop="completedCount" label="已完成任务数" width="180"></el-table-column>
      <el-table-column prop="taskCount" label="总任务数" width="180"></el-table-column>
      <el-table-column prop="endTime" label="任务截止时间" width="180"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="180"></el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      style="margin-top: 16px;text-align: right;"
      background
      :page-sizes="[20, 50, 100]"
      @size-change="handleSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems"
      :current-page="currentPage"
      :page-size="pageSize"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <createdTemplate ref="createdTemplate" @success="templateSuccess" />
    <createdIframe
      ref="createdIframe"
      :iframeUrl="iframeUrl"
      :iframeTitle="iframeTitle"
      @iframeClose="iframeClose"
    />
    <useModal ref="useModal" multiple @success="useSuccess" />
  </div>
</template>

<script>
import createdTemplate from './components/createdTemplate'
import createdIframe from './components/createdIframe'
import useModal from './components/useModal'
export default {
  name: 'TemplateList',
  components: {
    createdTemplate,
    createdIframe,
    useModal,
  },
  data() {
    return {
      loading: false,
      tableData: [],
      totalItems: 0,
      currentPage: 1,
      pageSize: 10,
      iframeUrl: '',
      iframeTitle: '',
      tenant:'',
      tenants:'',

    }
  },
  created() {
    this.getList()
    this.listTenantInfos()
  },
  methods: {
    //查询客户信息
    async listTenantInfos() {
      this.tableLoading = true;
      await this.api.salaryApi.finTenants().then(res => {
        console.log("-----",res)
          this.tenants = res.data;
        });
    },
    reset() {
      this.tenant = ''
      this.handleSearch()()
    },

    iframeClose(){
      this.getList()
    },
    templateSuccess(iframeUrl) {
      this.iframeUrl = iframeUrl
      this.iframeTitle = '创建模板'
      this.$nextTick(() => {
        this.$refs.createdIframe.open()
      })
    },
    useSuccess(iframeUrl) {
      this.iframeUrl = iframeUrl
      this.iframeTitle = '使用模板'
      this.$nextTick(() => {
        this.$refs.createdIframe.open()
      })
    },
    async handleEdit(row) {
      try {
        const res = await this.api.contractApi.getEditUrl({
          id: row.id,
          redirectUrl: window.location.origin + window.location.pathname + 'contract-success.html',
        })
        if (res.data.code === 200) {
          this.iframeUrl = res.data.data
          this.iframeTitle = '编辑模板'
          this.$nextTick(() => {
            this.$refs.createdIframe.open()
          })
        } else {
          this.$message.error(res.data.data.msg)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getList() {
      const data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        tenantId: localStorage.getItem('platform'),
      }
      if (this.tenant) {
        data.tenant = this.tenant
      }

      this.loading = true
      this.api.contractApi
        .batchSignTask(data)
        .then((res) => {
          if (res.data.code === 200) {
            this.tableData = res.data.data.records
            this.totalItems = res.data.data.total
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch() {
      this.currentPage = 1
      this.getList()
      // 实现搜索逻辑
    },
    handleUse(row) {
      // 使用模板的逻辑
      this.$refs.useModal.open(row.id)
    },
    async handleEnable(row) {
      // 启用模板的逻辑
      try {
        const res = await this.api.contractApi.enableTemplate({ id: row.id })
        if (res.data.code === 200) {
          row.status = 'valid'
          this.$message.success('启用成功')
        } else {
          this.$message.error(res.data.data.msg)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleDisable(row) {
      // 禁用模板的逻辑
      try {
        const res = await this.api.contractApi.disableTemplate({ id: row.id })
        if (res.data.code === 200) {
          row.status = 'invalid'
          this.$message.success('禁用成功')
        } else {
          this.$message.error(res.data.data.msg)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handlePreview(row) {
      // 预览模板的逻辑
      try {
        const res = await this.api.contractApi.getPreviewUrl({
          id: row.id,
        })
        if (res.data.code === 200) {
          this.iframeUrl = res.data.data
          this.iframeTitle = '预览模板'
          this.$nextTick(() => {
            this.$refs.createdIframe.open()
          })
        } else {
          this.$message.error(res.data.data.msg)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val
      // 加载新页面的数据
      this.getList()
    },
    handleSizeChange(value) {
      this.pageSize = value
      this.currentPage = 1
      this.getList()
    },
  },
}
</script>

<style scoped>
.select-box {
  display: flex;
}
.input-with-select {
  width: 300px;
  margin-right: 16px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.template-list {
  padding: 20px;
  background-color: #fff;
}
</style>

