<template>
  <div class="about" id="quotaReviewList">
    <div style="text-align: center;">
      <el-form :inline="true" :model="queryForm" class="demo-form-inline" :label-position="'right'" label-width="150px" ref="queryForm" :rules="rules">
        <el-col>
        <el-form-item label="收款方:" prop="tenant">
          <el-select v-model="queryForm.tenant" filterable clearable placeholder="请选择">
            <el-option
              v-for="item in tenantInfo"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        </el-col>
        <el-col>
        <el-form-item label="转账金额：" prop="amount">
          <el-input v-model="queryForm.amount" placeholder="请输入公司名称查询" :clearable="true"></el-input>
        </el-form-item>
        </el-col>
        <el-form-item label="转账备注：" prop="transferComment">
          <el-input v-model="queryForm.transferComment" placeholder="请输入公司名称查询" :clearable="true"></el-input>
        </el-form-item>
        <el-col>
        <el-form-item>
          <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
          <el-button type="primary" @click="tran" icon="el-icon-sort">转账</el-button>
        </el-form-item>
        </el-col>
      </el-form>
    </div>
   
    
    
  </div>
</template>

<script>

export default {
    data() {
      return {
        tenantInfo: [],
        queryForm: {
          
          tenant: '',
          amount: '',
          transferComment:'',
          sessionId: this.$getCookie("sessionId"),
        },
        rules: {
          tenant: [
            { required: true, message: '请选择收款方！', trigger: 'blur' }
          ],
          amount: [
            { required: true, message: '请输入转账金额！', trigger: 'blur' }
          ],
          transferComment: [
            { required: true, message: '请输入转账备注！', trigger: 'blur' }
          ],
        }
      }
    },
    async created(){
      this.listTenantInfos();
     
    },
    methods: {
      
      //查询客户信息
    async listTenantInfos() {
      await this.api.salaryApi.finTenantAndz(this.queryForm).then(res => {
        console.log("-----",res)
          this.tenantInfo = res.data;
        });
    },
    
        //添加活动
    async zzs(){
      await this.$refs['queryForm'].validate((valid) => {
        if (valid) {
         this.api.salaryApi.deposit(this.queryForm).then(res => {
          console.log("-----",res)
          // this.tenantInfos = res.data;
          if(res.data.code == 200){
            this.$message({
              type: 'success',
              message: '转账成功'
            });
            this.onReset();
          }else{
            this.$message.error(this.tenantInfo.msg);
          }
        });
        } else {
          return false;
        }
      });
    },
    tran() {
        this.$confirm('确认向该客户转账吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.zzs();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消转账'
          });          
        });
      },

    // 重置
      onReset(){
        this.queryForm.tenantId='';
        this.queryForm.amount='';
        this.queryForm.transferComment='';
      },
      
    },
      

     
      
      
      
      tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
      },
      tableCell(column) {
        return "height:40px;background-color:#fafafa;color:#000000;padding:0";
      },
      isEntity(val){
        if(val == null || val == undefined || val === ''){
            return true;
        }else{
            return false;
        }
      },
    
}
</script>

<style scoped>



#page {
  float: right;
  margin-top: 15px;
}

#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}

>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

>>> .el-drawer.rtl {
  overflow: scroll;
}

.wageadjustment{
  margin: 0 50px;
}

</style>

