import '@/plugins/axios';

// export const listPageItem = params => axios.post("/billing/listPageItem", params).then(data => data)

// 分页查询合同模板 /template/page
export const listPageTemplate = params => axios.post("/template/page",params).then(data => data)

// 创建模板 /template/create
export const createTemplate = params => axios.post("/template/create", params).then(data => data)

// 上传模板文件 /template/upload-doc
export const uploadDoc = params => axios.post("/template/upload-doc", params).then(data => data)

// 启用模板 /template/enable
export const enableTemplate = params => axios.post("/template/enable", params).then(data => data)

// 停用模板 /template/disable
export const disableTemplate = params => axios.post("/template/disable", params).then(data => data)

// 获取编辑模板url /template/get-edit-url/{id}
export const getEditUrl = params => axios.post("/template/get-edit-url", params).then(data => data)

// 获取预览模板url /template/get-preview-url/{id}
export const getPreviewUrl = params => axios.post("/template/get-preview-url", params).then(data => data)

// 发起签署 /template/start
export const start = params => axios.post("/template/start", params).then(data => data)

// 合同管理
// 分页查询合同数据 /contract/page
export const listPageContract = params => axios.post("/contract/page", params).then(data => data)

// 获取合同编辑链接 /contract/get-edit-url
export const getContractEditUrl = params => axios.post("/contract/get-edit-url", params).then(data => data)

// 获取合同预览链接 /contract/get-preview-url
export const getContractPreviewUrl = params => axios.post("/contract/get-preview-url", params).then(data => data)

// 获取签署任务预填写链接 /contract/get-prefill-url
export const getPreviewWrite = params => axios.post("/contract/get-prefill-url", params).then(data => data)

// 获取文档下载链接  /contract/get-download-url
export const getDownloadUrl = params => axios.post("/contract/get-download-url", params).then(data => data)

// 催办合同签署 /contract/urge
export const urgeContract = params => axios.post("/contract/urge", params).then(data => data)

// 撤销签署任务  /contract/cancel
export const cancelContract = params => axios.post("/contract/cancel", params).then(data => data)

// 延期签署任务  /contract/extension
export const extension = params => axios.post("/contract/extension", params).then(data => data)

// 获取下载合同结果连接
export const getDownloadResultUrl = downloadId => axios.post(`/contract/get-download-url/${downloadId}`).then(data => data)

// 合同签署情况统计
export const getContractStatis = () => axios.post('/contract/last-month-employee-contract-statis').then(data => data)

// 导出上月签署中的人员列表
export const exportSigning = () => axios.post('/contract/export-last-month-sign-employee',null,{responseType: 'blob'}).then(data => data)

// 导出上月未签署的人员列表
export const exportNotSigning = () => axios.post('/contract/export-last-month-not-sign-employee',null,{responseType: 'blob'}).then(data => data)

// 查询当前租户是否为平台租户
export const isPlatform = params => axios.post('/salarySummarizing/isPlatform',params).then(data => data)

// 删除模板
export const deteleTemplate = params => axios.post('/template/delete',params).then(data => data)

// 查询批量任务列表
export const batchSignTask = params => axios.post('/batch-sign-task',params).then(data => data)

// 批量发起接口
export const batchSignStart = params => axios.post('/batch-sign-task/start',params).then(data => data)

// 获取模版填充字段列表
export const templateGetFillFields = params => axios.post('/template/get-fill-fields',params).then(data => data)

// 复制模板
export const copyTemplate = params => axios.post('/template/copy',params).then(data => data)

// 批量催办合同签署
export const batchUrgeContract = params => axios.post("/contract/batchUrge", params).then(data => data)






















